import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LogoService } from 'src/app/logo.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
declare let $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  themeColor:Observable<string>;
  logoUrl: Observable<string>;
  themeName: any = '';
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  loading = false;
//  showErrorMessage: any = false;
//  errorMessage: any;
  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private authService: AuthService, private logoService: LogoService) {}

  ngOnInit() {
    this.themeColor = this.logoService.themeColor;
    this.logoUrl = this.logoService.logoUrl$;
    this.themeName = this.logoService.themeName;
    console.log(this.logoUrl);
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const remember = $.cookie('finRemember');
    console.log(remember);
    if (remember === 'true') {
      
      const username = $.cookie('finUsername');
      const password = $.cookie('finPassword');
      console.log(username);
      this.loginForm.controls.email.setValue(username);
      this.loginForm.controls.password.setValue(password);
      $('#checkmeout0').prop('checked', true);
    } else if (remember === 'false') {
      console.log('test');
      this.loginForm.controls.email.setValue('');
      this.loginForm.controls.password.setValue('');
      $('#checkmeout0').prop('checked', false);
    }
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
    document.body.classList.add('padding-bottom-remove');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    if (($('#checkmeout0').is(':checked'))) {
      const email = this.f.email.value;
      const password = this.f.password.value;
      $.cookie('finUsername', email, { expires: 14 });
      $.cookie('finPassword', password, { expires: 14 });
      $.cookie('finRemember', true, { expires: 14 });
    } else {
      // $.cookie('finUsername', '');
      // $.cookie('finPassword', '');
      // $.cookie('finRemember', false);
    }
    const body = {
      email: this.f.email.value,
      password: this.f.password.value,
    };
    this.authService.postlogin(body)
    .then((response: any) => {
      if (response.success) {
        this.loading = false;
        const userDetails = response.response.member;
        console.log('logg',userDetails);

        if(userDetails.role_id == 11 || userDetails.role_id == 10){
          Swal.fire({
            type: 'error',
            title: "Access denied!",
            confirmButtonClass: 'btn btn-confirm mt-2',
        });
        }else{
         if(userDetails.role_id == 10){
        localStorage.setItem('partnerId', userDetails.id);
        localStorage.setItem('partnerName', userDetails.name);
        if(userDetails.partner != null){
          localStorage.setItem('partnerMainId', userDetails['partner'].id);
        }
      }
        
        if (userDetails.status == 1) {
          localStorage.setItem('authToken', JSON.stringify(response.response.token));
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          if (userDetails.role !== null) {
            if (userDetails.role.dashboard_view === 1) {
              if(userDetails.role.id == 1 || userDetails.role.id == 14 ){
              this.router.navigate(['/dashboards/dashboard-view']);
              }else if(userDetails.role.id == 3){
              this.router.navigate(['/dashboards/operations-dashboard']);
              }else if(userDetails.role.id == 2){
              this.router.navigate(['/dashboards/underwriter-dashboard']);
              }else if(userDetails.role.id == 4){
              this.router.navigate(['/dashboards/customersupport-dashboard']);
              }else if(userDetails.role.id == 5){
                this.router.navigate(['/dashboards/collections-dashboard']);
              }else if(userDetails.role.id == 10){
                this.router.navigate(['/dashboards/partners-dashboard']);
                }
            } else {
              if (userDetails.role.customer_search_view === 1) {
                this.router.navigate(['/customers/customer-support']);
                return;
              } else if (userDetails.role.customer_validation_view === 1) {
                this.router.navigate(['/customers/customer-validation']);
                return;
              } else if (userDetails.role.customer_support_view === 1) {
                this.router.navigate(['/customers/customer-support']);
                return;
              } else if (userDetails.role.document_upload_view) {
                this.router.navigate(['/customers/document-upload']);
                return;
              } else if (userDetails.role.loan_disbursment_view) {
                this.router.navigate(['/loans/loan_disbursements']);
                return;
              } else if (userDetails.role.loan_repayment_view) {
                this.router.navigate(['/loans/loan_repayments']);
                return;
              } else if (userDetails.role.plan_pricing_view) {
                this.router.navigate(['/configurations/plan_pricing']);
                return;
              } else if (userDetails.role.discount_offers_view) {
                this.router.navigate(['/configurations/discount_offers']);
                return;
              } else if (userDetails.role.partner_list_view) {
                this.router.navigate(['/configurations/partner_list']);
                return;
              }  else if (userDetails.role.partner_customers_view) {
                this.router.navigate(['/configurations/partner-customers']);
                return;
              } 
               else if (userDetails.role.organisations_list_view) {
                this.router.navigate(['/configurations/Organisation_list']);
                return;
              } else if (userDetails.role.bank_details_view) {
                this.router.navigate(['/configurations/bank_details']);
                return;
              } else if (userDetails.role.categories_view) {
                this.router.navigate(['/configurations/categories']);
                return;
              } else if (userDetails.role.terms_conditions_view) {
                this.router.navigate(['/configurations/terms_condition']);
                return;
              } else if (userDetails.role.reports) {
                this.router.navigate(['/reports/operation_report']);
                return;
              } else if (userDetails.role.ratings_feedbacks) {
                this.router.navigate(['/ratingandfeedback/rating_feedback']);
                return;
              } else {
                Swal.fire({
                  type: 'error',
                  title: 'You cannot login',
                  confirmButtonClass: 'btn btn-confirm mt-2',
              });
              }
            }
          } else {
            Swal.fire({
              type: 'error',
              title: 'You cannot login',
              confirmButtonClass: 'btn btn-confirm mt-2',
          });
          }
        } else {
          Swal.fire({
            type: 'error',
            title: 'You cannot login',
            text: 'Your profile is not active',
            confirmButtonClass: 'btn btn-confirm mt-2',
        });
        }
      }
      }
      if (!response.success) {
      //  this.showErrorMessage = true;
        this.error = response.message;
        this.loading = false;
      }
    }).catch(error => {
      this.loading = false;
      this.error = error.message;
    });
    // this.authenticationService.login(this.f.email.value, this.f.password.value)
    //   .pipe(first())
    //   .subscribe(
    //     data => {
    //       this.router.navigate(['dashboard-view']);
    //     },
    //     error => {
    //       this.error = error;
    //       this.loading = false;
    //     });
   }
   ngOnDestroy() {
    document.body.classList.remove('padding-bottom-remove');
   }
}
