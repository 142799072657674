import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  private logoUrlSource = new BehaviorSubject<string>('./assets/images/logo/logo.png');
  private iconUrlSource = new BehaviorSubject<string>('./assets/images/logo/logo_sm.png');
  private themeColorSource = new BehaviorSubject<string>('#1F3D0C');
  private themeNameSource = new BehaviorSubject<string>('Company Name');
  
  logoUrl$ = this.logoUrlSource.asObservable();
  iconUrl$ = this.iconUrlSource.asObservable();
  themeColor = this.themeColorSource.asObservable();
  themeName = this.themeNameSource.asObservable();

  constructor(private titleService:Title) {
    this.titleService.setTitle("Company Name");
  }

}
