import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseUrl = environment.baseAPIUrl;
  public login = this.baseUrl + 'admin/login';
  public logout = this.baseUrl + 'admin/logout';
  public notifications = this.baseUrl + 'getnotifications?perpage=';
  public updateNotifications = this.baseUrl + 'setnotifications';
  public forgotpassword = this.baseUrl + 'admin/forgot';
  public resetpassword = this.baseUrl + 'admin/reset?email=';
  constructor(private http: HttpClient) { }

  postlogin(body: any) {
    return this.http.post(this.login, body)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  postlogout(body: any) {
    return this.http.get(this.logout, body)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  getNotifications(input: any) {
    return this.http.get(this.notifications + input)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  postupdateNotification(body: any) {
    return this.http.post(this.updateNotifications, body)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  postforgotPassword(body: any) {
    return this.http.post(this.forgotpassword, body)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  postresetPassword(input: any, body: any) {
    return this.http.post(this.resetpassword + input, body)
    .toPromise()
    .then(response => response)
    .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    if (error.status === 401) {
      return Promise.reject(error);
    }
    if (error.status === 400) {
      return Promise.reject(error);
    }
    if (error.status === 500) {
      return Promise.reject(error);
    }
    if (error.status === 404) {
      return Promise.reject(error);
    }
    if (error.status === 601) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}
