import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { ConfirmComponent } from './account/auth/confirm/confirm.component';


const routes: Routes = [
  { path: 'account',  loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '',  component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'api/account/reset-password', component: ConfirmComponent},
  { path: 'digitap', loadChildren: () => import('./pages/digitap/digitap.module').then(m => m.DigitapModule)},
  { path: 'signdesk', loadChildren: () => import('./pages/sign-desk/sign-desk.module').then(m => m.SignDeskModule)},
  { path: 'payemiamount/:id/:loanid', loadChildren: () => import('./pages/paymentscreen/paymentscreen.module').then(m => m.PaymentscreenModule)},
  { path: 'cashfree', loadChildren: () => import('./pages/cash-free/cash-free.module').then(m => m.CashFreeModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
