import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LogoService } from 'src/app/logo.service';
@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit, AfterViewInit, OnDestroy {
  themeColor:Observable<string>;
  logoUrl: Observable<string>;
  resetForm: FormGroup;
//  loginForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;
  isshow: any = false;
  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authService: AuthService, private toastr: ToastrService, private logoService: LogoService) { }

  ngOnInit() {
    this.themeColor = this.logoService.themeColor;
    this.logoUrl = this.logoService.logoUrl$;
    // this.loginForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    // });
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
    document.body.classList.add('padding-bottom-remove');
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;
    const body = {
      email: this.f.email.value
    };
    this.authService.postforgotPassword(body)
    .then((response: any) => {
      if (response.success) {
        this.toastr.success('Success!!', response.message, {
          closeButton: true,
          positionClass: 'toast-top-right',
          timeOut: 4000
        });
        this.loading = false;
        this.isshow = true;
      }
      if (!response.success) {
        this.toastr.error(response.message);
        this.error = response.message;
        this.loading = false;
      }
    }).catch(error => {
      this.toastr.error(error.message);
      this.loading = false;
      this.error = error.message;
    });
    // console.log(this.resetForm.value);
    // setTimeout(() => {
    //   this.loading = false;
    //   this.success = 'We have sent you an email containing a link to reset your password';
    // }, 1000);
  }
  ngOnDestroy() {
    document.body.classList.remove('padding-bottom-remove');
   }
}
