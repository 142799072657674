import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthService } from './../../account/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { LogoService } from 'src/app/logo.service';
declare var $: any;
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  logoUrl: Observable<string>;
  iconUrl: Observable<string>;
  themeColor:Observable<string>;
  userName: any = '';
  limit: any = 10;
  currentPage: any = 1;
  active: any;
  notificationItems: any = [];
  notificationId: any = [];
  totalNotificationCount: any = 0;
  userRoledetails: any;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };


  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  private notificationSubscription: Subscription;
  constructor(private router: Router, private authService: AuthService, public sharedService: SharedService, private logoService: LogoService) {
    if (localStorage.getItem('userDetails')) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.userName = userDetails.name;
      this.userRoledetails = userDetails;
    }
    this.notificationSubscription = this.sharedService.totalnotifycount.subscribe((data) => {
      this.totalNotificationCount = data.totalcount;
    });
  }

  ngOnInit() {
    // get the notifications
    this.logoUrl = this.logoService.logoUrl$;
    this.iconUrl = this.logoService.iconUrl$;
    this.themeColor = this.logoService.themeColor;
    console.log(this.themeColor);
    this._fetchNotifications();
    this.openMobileMenu = false;
    setTimeout(() => {
      $('.nav-second-level').removeClass('in');
    }, 100);
    if (this.userRoledetails.status == 1) {
      //console.log(this.userRoledetails.role);
      //  console.log(this.userRoledetails.role.customer_details_view);
      if (this.userRoledetails.role !== null) {
        if (this.userRoledetails.role.dashboard_view === 1) {
          if (this.userRoledetails.role.id == 1 || this.userRoledetails.role.id == 14) {
            //this.router.navigate(['/dashboards/dashboard-view']);
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/dashboards/dashboard-view']);
              this.active = 0;
              return;
            }
            if (this.router.url.includes('/dashboards/vendors-stats')) {
              this.router.navigate(['/dashboards/vendors-stats']);
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-support')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/loans/add_collections')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/loans/initiate_autodebits')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/loans/sendpaymentlink')) {
              this.active = 0;
              return;
            }

            else if (this.router.url.includes('/customers/customer-disbusdetails')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-allinfo')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-details')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/deviation-details')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-verification')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-callmatching')) {
              this.active = 0;
              return;
            } else if (this.router.url.includes('/customers/document-upload')) {
              this.active = 0;
              return;
            }
            else {
              this.router.navigate(['/dashboards/dashboard-view']);
              // console.log('underhere');
              return;
            }
          } else if (this.userRoledetails.role.id == 3) {
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/dashboards/operations-dashboard']);
              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else {
              this.router.navigate(['/dashboards/operations-dashboard']);
              return;
            }
          } else if (this.userRoledetails.role.id == 2) {
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/dashboards/underwriter-dashboard']);

              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-support')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-details')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-verification')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/deviation-details')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-callmatching')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else {
              this.router.navigate(['/dashboards/underwriter-dashboard']);
              return;
            }
          } else if (this.userRoledetails.role.id == 4 || this.userRoledetails.role.id == 3) {
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/customers/customer-support']);
              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else {
              this.router.navigate(['/customers/customer-support']);
              return;
            }
          } else if (this.userRoledetails.role.id == 5) {
            //  console.log('heress',this.userRoledetails.role);  
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/dashboards/collections-dashboard']);
              return;
            }
            else if (this.router.url.includes('/customers/deviation-reference-check')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-support')) {
              this.active = 0;
              return;
            } else if (this.router.url.includes('/loans/add_collections')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/loans/sendpaymentlink')) {
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/customer-details')) {
              //  console.log('heress',this.userRoledetails.role);                     
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/dashboards/collections-dashboard')) {
              // console.log('heress',this.userRoledetails.role);                     
              this.active = 0;
              return;
            }
            else if (this.router.url.includes('/customers/deviation-details')) {
              this.active = 0;
              return;
            }
            else {
              this.router.navigate(['/dashboards/collections-dashboard']);
              return;

            }


          } else if (this.userRoledetails.role.id == 10) {
            if (this.router.url.includes('/dashboards/dashboard-view')) {
              this.router.navigate(['/dashboards/partners-dashboard']);
              return;
            } else {
              this.router.navigate(['/dashboards/partners-dashboard']);
              return;
            }

          }

        } else {
          if (this.userRoledetails.role.customer_search_view === 1) {
            //  this.router.navigate(['/customers/customer-support']);
            // console.log('heress',this.userRoledetails.role);     
            if (this.userRoledetails.role.id == 4 || this.userRoledetails.role.id == 3) {
              if (this.router.url.includes('/dashboards/dashboard-view')) {
                this.router.navigate(['/customers/customer-support']);
                return;
              }
              else if (this.router.url.includes('/customers/deviation-reference-check')) {
                this.active = 0;
              }
              else if (this.router.url.includes('/customers/customer-support')) {
                this.active = 0;
              }
              else if (this.router.url.includes('/customers/customer-details')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/document-upload')) {
                this.active = 0;
                return;
              }
              else {
                this.router.navigate(['/customers/customer-support']);
                return;
              }

            }

            if (this.userRoledetails.role.id == 5) {
              if (this.router.url.includes('/dashboards/dashboard-view')) {
                this.router.navigate(['/dashboards/collections-dashboard']);
                return;
              }
              else if (this.router.url.includes('/customers/deviation-reference-check')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/deviation-details')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/customer-support')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/customer-details')) {
                //  console.log('heress',this.userRoledetails.role);                     
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/deviation-reference-check')) {
                this.active = 0;
                return;
              }
              else {
                this.router.navigate(['/dashboards/collections-dashboard']);
                return;
              }

            }
            if (this.userRoledetails.role.id == 2) {
              if (this.router.url.includes('/dashboards/dashboard-view')) {
                this.router.navigate(['/dashboards/underwriter-dashboard']);
                return;
              }
              else if (this.router.url.includes('/customers/customer-support')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/deviation-reference-check')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/customer-details')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/customer-verification')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/deviation-details')) {
                this.active = 0;
                return;
              }
              else if (this.router.url.includes('/customers/customer-callmatching')) {
                this.active = 0;
                return;
              }
              else {
                this.router.navigate(['/dashboards/underwriter-dashboard']);
                return;
              }

            }

          }
          else if (this.userRoledetails.role.customer_details_view === 1) {

            if (this.router.url.includes('/customers/customer-details')) {
              this.active = 0;
            }

            return;
          } else if (this.userRoledetails.role.customer_verification_view === 1) {
            // console.log(this.userRoledetails.role.customer_details_view);
            if (this.router.url.includes('/customers/customer-verification')) {
              this.active = 0;
            }

            return;
          } else if (this.userRoledetails.role.customer_validation_view === 1) {
            this.router.navigate(['/customers/customer-validation']);
            return;
          }
          else if (this.router.url.includes('/customers/deviation-reference-check')) {
            this.active = 0;
            return;
          } else if (this.userRoledetails.role.customer_support_view === 1) {
            this.router.navigate(['/customers/customer-support']);
            return;
          } else if (this.userRoledetails.role.document_upload_view) {
            this.router.navigate(['/customers/document-upload']);
            return;
          }
          else if (this.userRoledetails.role.document_upload_view) {
            this.router.navigate(['/customers/document-upload']);
            return;
          } else if (this.userRoledetails.role.loan_disbursment_view) {
            this.router.navigate(['/loans/loan_disbursements']);
            return;
          } else if (this.userRoledetails.role.loan_repayment_view) {
            this.router.navigate(['/loans/loan_repayments']);
            return;
          } else if (this.userRoledetails.role.plan_pricing_view) {
            this.router.navigate(['/configurations/plan_pricing']);
            return;
          } else if (this.userRoledetails.role.discount_offers_view) {
            this.router.navigate(['/configurations/discount_offers']);
            return;
          } else if (this.userRoledetails.role.partner_list_view) {
            this.router.navigate(['/configurations/partner_list']);
            return;
          } else if (this.userRoledetails.role.partner_customers_view) {
            this.router.navigate(['/configurations/partner-customers']);
            return;
          }
          else if (this.userRoledetails.role.organisations_list_view) {
            this.router.navigate(['/configurations/Organisation_list']);
            return;
          } else if (this.userRoledetails.role.bank_details_view) {
            this.router.navigate(['/configurations/bank_details']);
            return;
          } else if (this.userRoledetails.role.categories_view) {
            this.router.navigate(['/configurations/categories']);
            return;
          } else if (this.userRoledetails.role.terms_conditions_view) {
            this.router.navigate(['/configurations/terms_condition']);
            return;
          } else if (this.userRoledetails.role.reports) {
            this.router.navigate(['/reports/operation_report']);
            return;
          } else if (this.router.url.includes('/customers/deviation-reference-check')) {
            this.active = 0;
            return;

          } else if (this.userRoledetails.role.ratings_feedbacks) {
            this.router.navigate(['/ratingandfeedback/rating_feedback']);
            return;
          } else {
            Swal.fire({
              type: 'error',
              title: 'You cannot login',
              confirmButtonClass: 'btn btn-confirm mt-2',
            });
          }
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'You cannot login',
          confirmButtonClass: 'btn btn-confirm mt-2',
        });
      }
    } else {
      Swal.fire({
        type: 'error',
        title: 'You cannot login',
        text: 'Your profile is not active',
        confirmButtonClass: 'btn btn-confirm mt-2',
      });
    }

  }


  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
    setTimeout(() => {
      $('.sidebar-enable .metismenu').click(function () {
        $('.nav-second-level').removeClass('in');
      });
    });
  }

  /**
   * Logout the user
   */
  logout() {
    const body = {};
    this.authService.postlogout(body)
      .then((response: any) => {
        if (response.success) {
        }
        if (!response.success) {
        }
      }).catch(error => {
      });
    localStorage.clear();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.authService.getNotifications(this.limit + '&page=' + this.currentPage)
      .then((response: any) => {
        if (response.success) {
          this.notificationItems = response.response.notifications.data;
          this.totalNotificationCount = response.response.unread_notifications;
          this.notificationId = this.notificationItems.map(({ id }) => id);
        }
        if (!response.success) {
        }
      }).catch(error => {
      });
  }
  updateNotification() {
    if (this.notificationId.length > 0) {
      const body = {
        ids: this.notificationId
      };
      this.authService.postupdateNotification(body)
        .then((response: any) => {
          if (response.success) {
            this.totalNotificationCount = response.response.unread_notifications;
          }
          if (!response.success) {
          }
        }).catch(error => {
        });
    }
  }
  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }
}
