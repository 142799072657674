// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { AuthenticationService } from '../services/auth.service';

// @Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate {
//     token: any;
//     constructor(
//         private router: Router,
//         private authenticationService: AuthenticationService
//     ) { }

//     canActivate(
//         next: ActivatedRouteSnapshot,
//         state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//         this.token = JSON.parse(localStorage.getItem('authToken'));
//         if (this.token) {
//           return true;
//         } else {
//         this.router.navigate(['/account/login']);
//         return false;
//         }
//       }
//     // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     //     const currentUser = this.authenticationService.currentUser();
//     //     if (currentUser) {
//     //         // logged in so return true
//     //         return true;
//     //     }

//     //     // not logged in so redirect to login page with the return url
//     //     this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
//     //     return false;
//     // }
// }

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = JSON.parse(localStorage.getItem('authToken'));
        if (token) {

            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login']);
        return false;
    }
}
