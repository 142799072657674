import { Injectable, EventEmitter} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public global_object: any = new BehaviorSubject<any>(null);
  public componentData: any = {};
  public totalnotifycount = new EventEmitter();
  constructor() {
    this.totalnotifycount.emit();
    this.componentData = {
      dashboard: {
        menu: 'dashboard'
      },
      customersearch: {
        menu: 'customersearch'
      },
      customervalidation: {
        menu: 'customervalidation'
      },
      customersupport: {
        menu: 'customersupport'
      },
      documentupload: {
        menu: 'documentupload'
      },
      team: {
        menu: 'team'
      },
      loandisbursement: {
        menu: 'loandisbursement'
      },
      loanrepayment: {
        menu: 'loanrepayment'
      },
      planpricing: {
        menu: 'planpricing'
      },
      organization: {
        menu: 'organization'
      },
      offer: {
        menu: 'offer'
      },
      termscondition: {
        menu: 'termscondition'
      },
      category: {
        menu: 'category'
      },
      userrole: {
        menu: 'userrole'
      },
      bank: {
        menu: 'bank'
      },
      operationreports: {
        menu: 'operationreports'
      },
      customeropsreports: {
        menu: 'customeropsreports'
      },
      customerdetailsreports: {
        menu: 'customerdetailsreports'
      },
      collectionreports: {
        menu: 'collectionreports'
      },
      rating: {
        menu: 'rating'
      },
      notification: {
        menu: 'notification'
      }
    };
  }
  setDataInGlobalObject(data) {
    this.global_object.next(this.componentData[data]);
  }

}

