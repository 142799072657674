import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { LogoService } from 'src/app/logo.service';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, AfterViewInit, OnDestroy {
  themeColor:Observable<string>;
  logoUrl: Observable<string>;
  resetForm: FormGroup;
  submitted = false;
  error = '';
  loading = false;
  email: any = '';
  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private toastr: ToastrService, private route: ActivatedRoute, private logoService: LogoService) {
   }

  ngOnInit() {
    this.themeColor = this.logoService.themeColor;
    this.logoUrl = this.logoService.logoUrl$;
    this.resetForm = this.formBuilder.group({
      newpassword: ['', [Validators.required, Validators.minLength(8)]],
      cfmpassword: ['']
    }, { validator: this.checkPasswords });

    this.email = this.route.snapshot.queryParams['email'] || '';
    console.log(this.email);
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
    document.body.classList.add('padding-bottom-remove');
  }
  get f() { return this.resetForm.controls; }

  checkPasswords(group: FormGroup) {
    const pwd = group.controls.newpassword.value;
    const confirmpwd = group.controls.cfmpassword.value;
    return pwd === confirmpwd ? null : { notSame: true };
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }
    this.loading = true;
    const body = {
      password: this.f.newpassword.value,
      confirm_password: this.f.cfmpassword.value
    };
    this.authService.postresetPassword(this.email, body)
    .then((response: any) => {
      if (response.success) {
        this.toastr.success('Success!!', response.message, {
          closeButton: true,
          positionClass: 'toast-top-right',
          timeOut: 4000
        });
        this.loading = false;
        const userDetails = response.response.member;
        if (userDetails.status == 1) {
          localStorage.setItem('authToken', JSON.stringify(response.response.token));
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          this.router.navigate(['/dashboards/dashboard-view']);
        } else {
          Swal.fire({
            type: 'error',
            title: 'You cannot login',
            text: 'Your profile is not active',
            confirmButtonClass: 'btn btn-confirm mt-2',
        });
      }
      }
      if (!response.success) {
        this.toastr.error(response.message);
        this.error = response.message;
        this.loading = false;
      }
    }).catch(error => {
      this.toastr.error(error.message);
      this.loading = false;
      this.error = error.message;
    });
  }
  ngOnDestroy() {
    document.body.classList.remove('padding-bottom-remove');
   }
}
