import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { AuthenticationService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    authToken: any;
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (localStorage.getItem('authCustToken')) {
        request = request.clone({
          headers: new HttpHeaders({
            Accept:  'application/json',
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authCustToken'))
          })
        });
      } else if (localStorage.getItem('authToken')) {
            request = request.clone({
              headers: new HttpHeaders({
                Accept:  'application/json',
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authToken'))
              })
            });
          }  else if (localStorage.getItem('dauthType')) {
            request = request.clone({
              headers: new HttpHeaders({
                Authorization: 'Basic ' + 'NTI2NDg4Njg6cXNWTkhqY3E2RjZVRm1YUW5xdkl6VUpPZE5RN0xxWXI=',
                'Content-type': 'application/json'
              })
              // headers: new HttpHeaders({
              //   Authorization: 'Basic ' + 'MzgwNDE5Mjc6a1VXR0xBSjBpS2VPODg5a014bFNaVGxZVjRaS1hkbGo=',
              //   'Content-type': 'application/json'
              // })
            });
          }  else {
            request = request.clone({
              headers: new HttpHeaders({
                Accept:  'application/json',
              })
            });
          }
        return next.handle(request);
        }
        // add authorization header with jwt token if available
        // const currentUser = this.authenticationService.currentUser();
        // if (currentUser && currentUser.token) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${currentUser.token}`
        //         }
        //     });
        // }

        // return next.handle(request);
    //}
}
