import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { LogoService } from 'src/app/logo.service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  themeColor:Observable<string>;
  @Input() isCondensed = false;
  userRole: any;
  menu: any;
  global_object: any;
  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  constructor(public sharedService: SharedService, private router: Router,  private logoService: LogoService) {
    if (localStorage.getItem('userDetails')) {
      const userrole = JSON.parse(localStorage.getItem('userDetails'));
     // console.log('tes',  userrole);
      if (this.userRole !== null) {
        this.userRole = userrole.role;
       // console.log('tes',  this.userRole);
      
      }
    }
    this.sharedService.global_object.subscribe((data: any) => {
      this.global_object = data;
    });

  }

  ngOnInit() {
    this.themeColor = this.logoService.themeColor;
    setTimeout(() => {
     $('.sidebar-enable .metismenu').click(function () {
     $('.nav-second-level').removeClass('in');
  });
});
  }

  
    over(){
       $('.sidebar-enable .metismenu .nav-second-level').removeClass('in');
    }
    menu_click(){
      $('.sidebar-enable .metismenu .nav-second-level').removeClass('in');
    }
  


  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);

    this._activateMenuDropdown();
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * small sidebar
   */
  smallSidebar() {
    document.body.classList.add('left-side-menu-sm');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('enlarged');
  }

  /**
   * Dark sidebar
   */
  darkSidebar() {
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.add('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
    document.body.classList.remove('boxed-layout');
  }

  /**
   * Light Topbar
   */
  lightTopbar() {
    document.body.classList.add('topbar-light');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('boxed-layout');

  }

  /**
   * Sidebar collapsed
   */
  sidebarCollapsed() {
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.toggle('enlarged');
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('topbar-light');
  }

  /**
   * Boxed Layout
   */
  boxedLayout() {
    document.body.classList.add('boxed-layout');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.add('enlarged');
    document.body.classList.remove('left-side-menu-sm');
  }

  /**
   * Activates the menu dropdown
   */
  _activateMenuDropdown() {
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('active');

      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('in');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('active');
          parent3El.firstChild.classList.add('active');
        }
      }
    }
  }
  customerSearch() {
    if (this.userRole.customer_search_view === 1) {
      this.router.navigate(['/customers/customer-search']);
    }
  }
  ngOnDestroy() {
  }
}
